import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import { useDrawer } from "./useDrawer.js";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import PublishIcon from "@mui/icons-material/Publish";
import { Collapse } from "@mui/material";

import { Typography } from "../../../UIComponents/index.js";
import ProjectHealthReports from "./ProjectHealthReport.js";
import dayjs from "dayjs";

const ProjectRow = ({
  project,
  openProject,
  handleProjectClick,
  current_state_fields_mapping,
  historicalReportIds,
}) => {
  const { openDrawer } = useDrawer();
  const daysSinceStartDate = dayjs(dayjs()).diff(
    project.project_start_date,
    "day"
  );
  const isSubmitDisabled =
    daysSinceStartDate < 0 ||
    (project.days_since_last_report < 7 &&
      daysSinceStartDate > project.days_since_last_report);

  if (project.project_id === 44109504) {
    console.log({
      project,
      isSubmitDisabled,
      since_last: project.days_since_last_report,
      start_date_cal: dayjs(dayjs()).diff(project.project_start_date, "day"),
    });
  }
  const handleDrawerClick = () => {
    openDrawer(project);
  };
  return (
    <>
      <TableRow key={project.project_id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              handleProjectClick({
                project_id: project.project_id,
                historicalReportIds,
              })
            }
          >
            {openProject[project.project_id] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography>{project.project_title}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{project.manager_name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{project.project_lead}</Typography>
        </TableCell>
        {Object.keys(current_state_fields_mapping).map((key) => {
          return (
            <TableCell key={key}>
              <Typography>{`${project[key]}`}</Typography>
            </TableCell>
          );
        })}
        <TableCell>
          <IconButton
            aria-label="submit report"
            size="small"
            onClick={handleDrawerClick}
            disabled={isSubmitDisabled}
            sx={{ maxWidth: "36px" }}
          >
            <PublishIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={19}>
          <Collapse
            in={openProject[project.project_id]}
            timeout="auto"
            unmountOnExit
          >
            <ProjectHealthReports historicalReportIds={historicalReportIds} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectRow;

import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import IconButton from "../../../UIComponents/DesignSystem/IconButtton.js";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";

import { Collapse } from "@mui/material";
import { healthReportMapping } from "./CollapsibleTable.js";
import HistoricalHealthReportDetails from "./HistoricalHealthReportDetails.js";

const HistoricalHealthReportRow = ({ report, openPHR, handlePHRClick }) => {
  const shouldShowDetails = false;
  return (
    <>
      <TableRow style={{ padding: 0 }}>
        <TableCell />
        {shouldShowDetails && (
          <TableCell>
            <IconButton
              size="small"
              onClick={() => handlePHRClick(report.id)}
              sx={{ maxWidth: "36px" }}
            >
              {openPHR[report.id] ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        {Object.keys(healthReportMapping).map((key) => (
          <TableCell key={key}>{`${report[key]}`}</TableCell>
        ))}
      </TableRow>
      {shouldShowDetails && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={18}>
            <Collapse in={openPHR[report.id]} timeout="auto" unmountOnExit>
              <HistoricalHealthReportDetails report={report} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default HistoricalHealthReportRow;

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { deleteAuthToken } from "../utils";
import axios from "axios";


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setUserToken] = useState(null);

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = useCallback(
    (data) => {
      setUser(data.user);
      setUserToken(data.token);
      navigate('/dashboard');
    },
    [navigate, setUser, setUserToken]
  );

  const setToken = useCallback(
    (token) => {
      if (token) {
        setUserToken(token);
        const decoded = jwt_decode(token);
        if (!user?.userId) setUser(decoded);
      }
    },
    [user?.userId]
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    axios
      .delete(`${process.env.REACT_APP_PROD}/logout`, { data: { ips: null } })
      .then((res) => {
        setUserToken(null);
        setUser(null);
        deleteAuthToken();
        navigate("/", { replace: true });
      })
      .catch((err) => {
        console.log("LOGOUT", err);
      });

  }, [navigate]);

  const value = useMemo(
    () => ({
      user,
      token,
      setToken,
      setUser,
      login,
      logout,
    }),
    [user, login, logout, setUser, token, setToken]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

import React, { useState, useEffect, useCallback, useRef, Fragment } from "react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import SearchSelect from "../ComponentsLibrary/SearchSelect";

const AllClient = () => {
 
  const [clients, setClients] = useState([]);
  const [groupClient, setGroupClient] = useState([]);
  const [cid, setCid] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [selectedClient,setSelectedClient]=useState({});
  const isMountedRef = useRef(false); 
  const getOTP = useCallback (() => {
     axios.get(
      `${process.env.REACT_APP_PROD}/allclients`      
    ).then((res)=>{
      setClients(res.data);
    }).catch((err)=>console.log({err}));
  },[]);

  const getGroup = useCallback (() => {
     axios.get(
      `${process.env.REACT_APP_PROD}/clients`,
    ).then((response)=>{
      setGroupClient(response.data.sort(GetSortOrder("client_name")));
    });
  },[]);

  useEffect(()=>{
    if(!isMountedRef.current){getGroup()};
    getOTP();
  },[getOTP,getGroup]);


  useEffect(() => {
    const timer =
      seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    if (seconds === 0) {
      setSeconds(30);
      getOTP();
    }
    return () => clearInterval(timer);
  }, [seconds,getOTP]);



  const GetSortOrder = (prop) => {
    return (a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };


  return (
    <Fragment >
      <section className="hero" style={{width:'100%',background:'inherit'}}>
        <div className="hero-body " style={{width:'100%'}}>
            <SearchSelect
            fullWidth
            label={'Search Client'}
            options={groupClient}
            optionDisplayVar="client_name"
            value={selectedClient}
            onChange={(v)=>{ 
                setCid(v?.id || "");
                setSelectedClient(v?.id?v:{});
              }}     
            clearable                   
            />
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-12-desktop">                              
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div class="col-md-8 offset-2 block ">
            <span>
              <strong>Note:</strong> Wait for 30 seconds if code doesn't work.
              Do not <strong>REFRESH!!</strong>
            </span>
            <div class="circle" style={{background:'#7367F0'}}>
              <p>{seconds}</p>
            </div>
          </div>
          {clients &&
            clients.map((p, index) => (
              <>
                {cid === "" ? (
                  <div className="col-md-4 mb-5">
                    <div className="card">
                      <div className="card-body text-white" style={{background:'#7367F0'}}>
                        <h4 className="card-title" title="Client Name">
                          {" "}
                          {p.client_name}{" "}
                        </h4>
                        <p className="card-text">
                          {" "}
                          <span title="Account username">
                            {p.mtg_authenticator_details.account}
                          </span>{" "}
                          <br />
                          {p.mtg_authenticator_details.app_name}
                        </p>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-10">
                              <b>{p.mtg_authenticator_details.otp}</b>
                            </div>

                            <CopyToClipboard
                              text={p.mtg_authenticator_details.otp}
                            >
                              <div
                                className="col-md-2"
                                onClick={() =>
                                  toast(
                                    "Code has been copied - " +
                                      p.mtg_authenticator_details.otp
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-files"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                                </svg>
                              </div>
                            </CopyToClipboard>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <>
                    {p.id === cid ? (
                      <div className="col-md-4 mb-5">
                        <div className="card">
                          <div className="card-body text-white" style={{background:'#7367F0'}} >
                            <h4 className="card-title" title="Client Name">
                              {" "}
                              {p.client_name}{" "}
                            </h4>
                            <p className="card-text">
                              {" "}
                              <span title="Account username">
                                {p.mtg_authenticator_details.account}
                              </span>{" "}
                              <br />
                              {p.mtg_authenticator_details.app_name}
                            </p>
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <div className="row">
                                <div className="col-md-10">
                                  <b>{p.mtg_authenticator_details.otp}</b>
                                </div>
                                <CopyToClipboard
                                  text={p.mtg_authenticator_details.otp}
                                >
                                  <div
                                    className="col-md-2"
                                    onClick={() =>
                                      toast(
                                        "Code has been copied - " +
                                          p.mtg_authenticator_details.otp
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-files"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                                    </svg>
                                  </div>
                                </CopyToClipboard>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default AllClient;
import TableHead from "../../../UIComponents/DesignSystem/TableHead.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import { Typography } from "../../../UIComponents/index.js";

const TableHeadComponent = ({ columns, indent = 1 }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell colspan={indent} />
        {columns.map((col, index) => (
          <TableCell key={index}>
            <Typography>{col}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadComponent;

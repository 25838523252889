import { combineReducers } from "@reduxjs/toolkit";
import usersSlice from "./reducers/users";
import apiStatusSlice from "./reducers/apiStatus";
import workspacesSlice from "./reducers/workspaces";
import decisionsSlice from "./reducers/decisions";
import projectHealthDashboardSlice from "./reducers/projectHealthDashboard";
import membersReducer from "./reducers/members";

const createRootReducer = () =>
  combineReducers({
    apiStatus: apiStatusSlice,
    users: usersSlice,
    workspaces: workspacesSlice,
    decisions: decisionsSlice,
    projectHealthDashboard: projectHealthDashboardSlice,
    members: membersReducer,
  });

export default createRootReducer;

import {
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Button, TextField } from "../../../UIComponents/index.js";

import { useDrawer } from "./useDrawer.js";
import { memo, useCallback } from "react";
import dayjs from "dayjs";

const ReportDrawer = () => {
  const {
    drawerOpen,
    drawerData,
    setDrawerData,
    closeDrawer,
    submitProjectHealthReport,
  } = useDrawer();

  const handleSubmitReport = useCallback(() => {
    submitProjectHealthReport();
  }, [submitProjectHealthReport]);
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
      {drawerData && (
        <div style={{ width: 400, padding: 20 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Submit New Report</h3>
            <Button onClick={closeDrawer} color="secondary" variant="outlined">
              Close
            </Button>
          </div>

          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="client-engagement">
              Client Engagement
            </InputLabel>
            <Select
              labelId="client-engagement-label"
              id="client-engagement"
              value={drawerData.client_engagement}
              label="Client Engagement"
              onChange={(e) => {
                setDrawerData((prev) => ({
                  ...prev,
                  client_engagement: e.target.value,
                }));
              }}
            >
              <MenuItem value={1} title="Very Dissatisfied">
                1 (Very Dissatisfied)
              </MenuItem>
              <MenuItem value={2} title="Dissatisfied">
                2 (Dissatisfied)
              </MenuItem>
              <MenuItem value={3} title="Neutral">
                3 (Neutral)
              </MenuItem>
              <MenuItem value={4} title="Satisfied">
                4 (Satisfied)
              </MenuItem>
              <MenuItem value={5} title="Very Satisfied">
                5 (Very Satisfied)
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="client-timeliness">
              Client Timeliness
            </InputLabel>
            <Select
              labelId="client-timeliness-label"
              id="client-timeliness"
              value={drawerData.client_timeliness}
              label="Client Timeliness"
              onChange={(e) => {
                setDrawerData((prev) => ({
                  ...prev,
                  client_timeliness: e.target.value,
                }));
              }}
            >
              <MenuItem value={1} title="Very Late">
                1 (Very Late)
              </MenuItem>
              <MenuItem value={2} title="Late">
                2 (Late)
              </MenuItem>
              <MenuItem value={3} title="On Time">
                3 (On Time)
              </MenuItem>
              <MenuItem value={4} title="Ahead of Schedule">
                4 (Ahead of Schedule)
              </MenuItem>
              <MenuItem value={5} title="Significantly Ahead">
                5 (Significantly Ahead)
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Budget"
            value={`$${(
              parseFloat(drawerData.budget_remaining.replace(/[$,]/g, "")) +
              parseFloat(drawerData.budget_used.replace(/[$,]/g, ""))
            ).toLocaleString("en-US")}`}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            fullWidth
            label="On Schedule"
            value={dayjs(drawerData.project_close_date).format("MMM D, YYYY")}
            margin="normal"
            InputProps={{
              readOnly: true,
              style: {
                color:
                  new Date(drawerData.project_close_date) > new Date()
                    ? "green"
                    : "red",
              },
            }}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Overall Notes"
            placeholder="Start taking notes..."
            value={drawerData.overall}
            onChange={(e) =>
              setDrawerData((prev) => ({
                ...prev,
                overall: e.target.value,
              }))
            }
            margin="normal"
          />

          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={handleSubmitReport}
          >
            Submit Report
          </Button>
        </div>
      )}
    </Drawer>
  );
};

export default memo(ReportDrawer);

import React, { useEffect, useState } from "react";
import {
  Button,
  Popover,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekday from "dayjs/plugin/weekday";
import {
  fetchAllManagers,
  selectManagerIds,
} from "../../../redux/reducers/managers";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllLeads, selectLeadIds } from "../../../redux/reducers/leads";

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekday);

const isDayDisabled = (date) => {
  return dayjs(date).isSameOrAfter(dayjs(), "day") || dayjs(date).day() !== 1;
};

const getStartOfCurrentWeek = () => {
  return dayjs().weekday(1);
};

const getSavedFilters = () => {
  const savedFilters = localStorage.getItem("filters");
  if (savedFilters) {
    return JSON.parse(savedFilters);
  }
  return null;
};

const FilterPopover = ({ handleFilterChange }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  // Retrieve saved filters or use default values
  const savedFilters = getSavedFilters();

  const [selectedManagers, setSelectedManagers] = useState(
    savedFilters?.managers || []
  );
  const [selectedLeads, setSelectedLeads] = useState(savedFilters?.leads || []);
  const [isArchieved, setIsArchieved] = useState(
    savedFilters?.isArchieved || false
  );
  const [selectedDate, setSelectedDate] = useState(
    savedFilters?.start_date
      ? dayjs(savedFilters.start_date)
      : getStartOfCurrentWeek()
  );

  const allLeads = useSelector(selectLeadIds);
  const allManagers = useSelector(selectManagerIds);

  const leads = ["All", ...allLeads];
  const managers = ["All", ...allManagers];

  useEffect(() => {
    dispatch(fetchAllManagers());
    dispatch(fetchAllLeads());
  }, [dispatch]);

  useEffect(() => {
    setSelectedManagers(managers);
    setSelectedLeads(leads);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLeads, allManagers]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManagerChange = (value) => {
    setSelectedManagers((prev) => {
      const allSelectedPrev = prev.includes("All");
      const allSelectedCurrent = value.includes("All");
      if (!allSelectedPrev && allSelectedCurrent) {
        return managers;
      }
      if (allSelectedPrev && allSelectedCurrent) {
        const valueWithoutAll = value.filter((v) => v !== "All");
        if (valueWithoutAll.length !== managers.length) {
          return valueWithoutAll;
        }
      }
      if (allSelectedPrev && !allSelectedCurrent) {
        return [];
      }
      return value;
    });
  };

  const handleLeadChange = (value) => {
    setSelectedLeads((prev) => {
      const allSelectedPrev = prev.includes("All");
      const allSelectedCurrent = value.includes("All");

      if (!allSelectedPrev && allSelectedCurrent) {
        return leads;
      }

      if (allSelectedPrev && allSelectedCurrent) {
        const valueWithoutAll = value.filter((v) => v !== "All");
        if (valueWithoutAll.length !== leads.length) {
          return valueWithoutAll;
        }
      }

      if (allSelectedPrev && !allSelectedCurrent) {
        return [];
      }

      return value;
    });
  };

  const handleFilterSubmit = () => {
    const filters = {
      managers: selectedManagers.includes("All") ? "all" : selectedManagers,
      leads: selectedLeads.includes("All") ? "all" : selectedLeads,
      isArchieved,
      start_date: selectedDate,
      end_date: selectedDate.weekday(5),
    };

    localStorage.setItem("filters", JSON.stringify(filters));

    handleFilterChange({ filters });
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "300px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="manager-select-label">Select Managers</InputLabel>
            <Select
              labelId="manager-select-label"
              multiple
              value={selectedManagers}
              onChange={(e) => handleManagerChange(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
            >
              {managers.map((manager) => (
                <MenuItem key={manager} value={manager}>
                  <Checkbox checked={selectedManagers.indexOf(manager) > -1} />
                  {manager}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Leads Select */}
          <FormControl fullWidth>
            <InputLabel id="lead-select-label">Select Leads</InputLabel>
            <Select
              labelId="lead-select-label"
              multiple
              value={selectedLeads}
              onChange={(e) => handleLeadChange(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
            >
              {leads.map((lead) => (
                <MenuItem key={lead} value={lead}>
                  <Checkbox checked={selectedLeads.indexOf(lead) > -1} />
                  {lead}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Toggle Button for Active/Inactive */}
          <ToggleButtonGroup
            value={isArchieved}
            exclusive
            onChange={(e, newStatus) => {
              if (newStatus !== null) setIsArchieved(newStatus);
            }}
            aria-label="status toggle"
          >
            <ToggleButton value={false}>Active</ToggleButton>
            <ToggleButton value={true}>Inactive</ToggleButton>
          </ToggleButtonGroup>

          {/* Date Picker for First Day of the Week (Monday) */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Start of Week"
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              shouldDisableDate={isDayDisabled}
            />
          </LocalizationProvider>

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilterSubmit}
          >
            Submit
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default FilterPopover;

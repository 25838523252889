import TableCell from "../../../UIComponents/DesignSystem/TableCell.js";
import TableBody from "../../../UIComponents/DesignSystem/TableBody.js";
import TableRow from "../../../UIComponents/DesignSystem/TableRow.js";
import Table from "../../../UIComponents/DesignSystem/Table.js";
import TableHeadComponent from "./TableHeader.js";
import { current_state_fields_mapping } from "./CollapsibleTable.js";

import { Typography } from "../../../UIComponents/index.js";

const HistoricalHealthReportDetails = ({ report }) => (
  <Table aria-label="resources">
    <TableHeadComponent
      columns={Object.values(current_state_fields_mapping)}
      indent={2}
    />
    <TableBody>
      <TableRow>
        <TableCell colSpan={3}></TableCell>
        <TableCell />
        <TableCell />
        {Object.keys(current_state_fields_mapping).map((key) => (
          <TableCell key={key}>
            <Typography>{`${report[key]}`}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  </Table>
);

export default HistoricalHealthReportDetails;

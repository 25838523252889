import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { submitProjectHealthReport } from "../../../redux/reducers/projectHealthDashboard";
import { useAuth } from "../../../CustomHooks/AuthenticationHook";
import dayjs from "dayjs";

export const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
  const dispatch = useDispatch();
  let { user } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState(null);

  const openDrawer = useCallback((data) => {
    setDrawerData(data);
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
    setDrawerData(null);
  }, []);

  const submitProjectHealthReportHandler = useCallback(async () => {
    try {
      const snapshot = {
        budget_used: drawerData.budget_used,
        budget_remaining: drawerData.budget_remaining,
        total_billable_minutes: drawerData.total_billable_minutes,
        total_nonbillable_minutes: drawerData.total_nonbillable_minutes,
        total_minutes: drawerData.total_minutes,
        stories_unassigned: drawerData.stories_unassigned,
        incorrect_status: drawerData.incorrect_status,
        total_stories_count: drawerData.total_stories_count,
        stories_without_start_due_date:
          drawerData.stories_without_start_due_date,
        stories_past_due_date: drawerData.stories_past_due_date,
        esimate_hours_no_due_date: drawerData.esimate_hours_no_due_date,
        missing_estimation_time: drawerData.missing_estimation_time,
        pm_grade: drawerData.pm_grade,
      };
      const newReport = {
        workspace_id: drawerData.project_id,
        overall: drawerData.overall,
        schedule_date: dayjs().startOf("week").subtract(1, "day"),
        remaining_budget: drawerData.budget_remaining,
        remain_hours:
          (drawerData.total_minutes -
            (drawerData.total_billable_minutes +
              drawerData.total_nonbillable_minutes)) /
          60,
        rate: drawerData.project_rate,
        client_engagement: drawerData.client_engagement,
        client_timeliness: drawerData.client_timeliness,
        for_week_of: dayjs().startOf("week").add(2, "day"),
        submitted_on: new Date(),
        submitted_by: user.userId,
        snapshot,
      };


      const resultAction = await dispatch(submitProjectHealthReport(newReport));

      if (submitProjectHealthReport.fulfilled.match(resultAction)) {
        closeDrawer();
      } else {
        console.error(
          "Failed to submit report:",
          resultAction.payload || resultAction.error
        );
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerData]);

  const value = useMemo(
    () => ({
      drawerOpen,
      drawerData,
      openDrawer,
      closeDrawer,
      setDrawerData,
      submitProjectHealthReport: submitProjectHealthReportHandler,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawerOpen, drawerData]
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawer must be used within a DrawerProvider");
  }
  return context;
};

import React, { useEffect, useState, useCallback, useMemo } from "react";
// import PropTypes from "prop-types";
import { Avatar, Card, IconButton, Stack, Typography } from "@mui/material";
import { useAuth } from "../../CustomHooks/AuthenticationHook";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeamMembers, getUserTeamMembers } from "../../redux/reducers/users";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const TeamMembers = () => {
  const dispatch = useDispatch();
  const teamMembersMap = useSelector(getAllTeamMembers);
  const teamMembers = useMemo(() => Object.values(teamMembersMap || {}), [teamMembersMap]);
  const { userId } = useParams();
  let { user } = useAuth();
  const [directReportees, setDirectReportees] = useState([]);
  const [worksWith, setWorksWith] = useState([]);
  const [manages, setManages] = useState([]);
  if (userId) {
    user = { userId };
  }
  const navigate = useNavigate();

  const getDirectReportees = useCallback(() => {
    axios
      .post(`${process.env.REACT_APP_PROD}/getTargetMembers`, {
        sourceMemberId: user.userId,
        featureId: 11, //feature Id for view dashboard
      })
      .then((res) => {
        if (res?.data?.length>0) {
          const temp = res.data.map(Number);
          setDirectReportees(temp);
        }else{
          setDirectReportees([])
        }
      })
      .catch((err) => {
        console.log("There was some error fetching target members", err);
      });
  }, [user.userId]);

  useEffect(() => {
    dispatch(getUserTeamMembers({ userId: user.userId }));
  }, [user.userId, dispatch]);
  
  useEffect(() => {
    getDirectReportees();
  }, [getDirectReportees, userId])
  
  useEffect(() => {
    const worksWithTemp = []
    const managesTemp = []
    // console.log("Team members",teamMembers)
    // console.log("Direct reportees",directReportees)
    if(teamMembers.length>0 ){
      teamMembers.forEach((eachMember)=>{
        if(directReportees.includes(eachMember.id)){
          managesTemp.push(eachMember);
        }else{
          worksWithTemp.push(eachMember);
        }
      })
      setWorksWith(worksWithTemp);
      setManages(managesTemp);
    }
  }, [directReportees,teamMembers])
  

  return (
    < >
      <Card style={{marginBottom:"20px"}}>
        <Stack direction={"column"} p={2}>
          <Typography variant="Medium" fontSize={18} gutterBottom>
            Works With
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            flexWrap={"wrap"}
            useFlexGap
          >
            {worksWith.map((member, id) => (
              <Stack
                key={member.id}
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                onClick={() => {
                  navigate(`/member-profile/${member.id}`);
                }}
              >
                <IconButton>
                  <Avatar alt={member.name} src={member.photo}>
                    {member?.name.split("")[0] || "U"}
                  </Avatar>
                </IconButton>
                <Stack direction={"column"}>
                  <Typography variant="Medium" fontSize={14} fontWeight={600}>
                    {member.name}
                  </Typography>
                  <Typography variant="Regular" sx={{ color: "text.disabled" }}>
                    {member.hierarchy || "NA"}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Card>
      {manages.length > 0 && (
        <Card>
          <Stack direction={"column"} p={2}>
            <Typography variant="Medium" fontSize={18} gutterBottom>
              Manages
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              flexWrap={"wrap"}
              useFlexGap
            >
              {manages.map((member, id) => (
                <Stack
                  key={member.id}
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  onClick={() => {
                    navigate(`/dashboard/${member.id}`);
                  }}
                >
                  <IconButton>
                    <Avatar alt={member.name} src={member.photo}>
                      {member?.name.split("")[0] || "U"}
                    </Avatar>
                  </IconButton>
                  <Stack direction={"column"}>
                    <Typography variant="Medium" fontSize={14} fontWeight={600}>
                      {member.name}
                    </Typography>
                    <Typography
                      variant="Regular"
                      sx={{ color: "text.disabled" }}
                    >
                      {member.hierarchy || "NA"}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Card>
      )}
    </>
  );
};

// TeamMembers.propTypes = {};

export default TeamMembers;

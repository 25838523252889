import React,{useState,useEffect,useCallback} from "react";
// import PropTypes from "prop-types";
import { Grid, Stack } from "@mui/material";
import ProfileSummary from "./ProfileSummary";
import { TimeEntryProfileTabs } from "../TimeEntryProfile";
import TimeEntrySummary from "../TimeEntrySummary";
import TeamMembers from "../TeamMembers";
import MinimizedAuthCodes from "../MinimizedAuthCodes";
import { useAuth } from "../../CustomHooks/AuthenticationHook";
import { useParams } from "react-router-dom";
import axios from "axios";

const MemberPerfProfile = (props) => {
  const [isViewDashboardPermitted, setIsViewDashboardPermitted] = useState(false)
  let { user } = useAuth();
  const {userId} = useParams();
  const sourceMemberId =  user.userId;
  const targetMemberId = userId || sourceMemberId;
  if(userId){
    user = {userId}
  }
  const checkViewDashboardPermission = useCallback(()=>{
    axios.post(`${process.env.REACT_APP_PROD}/verifyFeaturePermission`,{
      sourceMemberId,
      targetMemberId,
      featureId:11//feature Id for view dashboard
    }).then((res)=>{
      if(res.data.isFeaturePermitted){
        setIsViewDashboardPermitted(true)
      }else{
        setIsViewDashboardPermitted(false)
      }
    }).catch((err)=>{
      console.log("There was some error checking permission",err);
    })
  })
  useEffect(() => {
    checkViewDashboardPermission()
  }, [])
  
  return (
    <Stack direction={"column"} pb={2} width={'100%'}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 2, xl: 4 }}
        width={"100%"}
        pb={2}
      >
        <Grid item xs={12} sm={4} md={4.75}  display={'flex'} flexDirection={'column'}>
          <ProfileSummary />
          {sourceMemberId == targetMemberId && <MinimizedAuthCodes/>}
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          {isViewDashboardPermitted && <TimeEntryProfileTabs user={user}/>}
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          {isViewDashboardPermitted && <TimeEntrySummary user={user}/>}
        </Grid>
      </Stack>
      <Grid item xs={12}>
        <TeamMembers />
      </Grid>
    </Stack>
  );
};

// MemberPerfProfile.propTypes = {};

export default MemberPerfProfile;
